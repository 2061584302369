import { Environment } from "../app/classes/flow/base/Environment";

export const ENVIRONMENT: Environment = {
  PRODUCTION: true,
  ORGANISATION: "Fixteam",
  API_URL: "https://staging.api.epf.fixteamdrenthe.nl/",
  CLIENT_ID: "fc0c8573-fe74-4809-99ac-aad548f30ef0", //app-registration -> client id
  AUTHORITY: "https://drentheepf.b2clogin.com/drentheepf.onmicrosoft.com/B2C_1_Staging_SignupAndSignin", //url + userflow
  KNOWN_AUTHORITY: "https://drentheepf.b2clogin.com",

  SOCIALS: {
    SLOGAN: "Wij hebben het zo voor u gefixt",
    LINKS: {
      MAIN: "https://fixteamdrenthe.nl/",
      PRIVACY: "https://fixteamdrenthe.nl/privcay-statement/",
      DISCLAIMER: "https://fixteamdrenthe.nl/disclaimer/",
    },
  },

  EXTRA_TRANSLATIONS_SUFFIX: [".fixteam"],
  MODULES: [
    "AREA_ACTIONS",
    "SHOW_ADDRESSDETAILS_TO_COACH_ROLE",
    "COACH_FINISH_REQUEST",
    "SHOW_ANOTHER_COACHES_ECS",
    "NOTE",
    "EDIT_ENERGY_CONSULT",
    "SHOW_CONTACT_INFO_ON_NEW_REQUESTS",
    "PHONE_NUMBER_REQUIRED",
    "MAIL_TIMELINE",
    "NEW_REQUEST_COACH",
    "NEW_REQUEST_COORDINATOR",
    "REPORT_CATEGORIES",
    "STATISTICS_DASHBOARD",
    "HIDE_AREA_ACTIONS_NEW_REQUEST_PAGE",
  ],
  AREA_OF_OPERATIONS: {
    MATCHES: [{ type: "include", key: "provincienaam", value: ["DRENTHE"] }],
  },
  CUSTOM_HEADER_LOGOS: [],
  ALLOWED_SPECIALTIES: [11],
  REDIRECTS: [],
};
